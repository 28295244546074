'use strict';



document.addEventListener("DOMContentLoaded", () => {


	// burger animation

	$('.cmn-toggle-switch').click(function(){
		let menu=$('.cmn-toggle-switch__htx');
		  if (menu.hasClass('active')) {			 
			$('.menu-wrap').animate({"right":"-100%"});		
		  } else {
			$('.menu-wrap').animate({"right":"0px"});
		
		  }		 
	});

	$('.js-about-more').click(function(e){
		e.preventDefault();
		// $('.about-content .content').css("height","auto");
		$('.about-content .content').toggleClass("open");
	});

	$('.block_overflow .more').click(function(e){
		e.preventDefault();
		$(this).closest('.block_overflow').find('.inner').slideToggle();
	});

	

	var toggles = document.querySelectorAll(".cmn-toggle-switch");
	for (var i = toggles.length - 1; i >= 0; i--) {
	  var toggle = toggles[i];
	  toggleHandler(toggle);
	};  
  
	function toggleHandler(toggle) {
		toggle.addEventListener( "click", function(e) {
		e.preventDefault();     
		if(this.classList.contains("active") === true) {
		  this.classList.remove("active")  		      
		} 
		else{
		 this.classList.add("active");   		 
		} 
	  });
	}


	document.addEventListener( 'wpcf7mailsent', function( event ) {
		if (
			('5' == event.detail.contactFormId)||
			('94' == event.detail.contactFormId)||
			('95' == event.detail.contactFormId)
		) {
		$.fancybox.close();
		 $.fancybox.open({
		  src: '#modal-success',
		  type: 'inline',
		  'showCloseButton':false
		});
	
		setTimeout(function(){
		  $.fancybox.close();
		}, 3000);
		}
	}, false );

	// menu open
	// document.querySelector('.cmn-toggle-switch').onclick = function() {
	// 	let menuwrap=document.querySelector(".header__col.nav");
	// 	if(menuwrap.classList.contains("open-menu") === true) {
	// 		menuwrap.classList.remove("open-menu");   
	// 		document.querySelector('.header__col.nav').classList.toggle('fade-in');		
	// 	} else{
	// 		menuwrap.classList.add("open-menu");    
	// 		document.querySelector('.header__col.nav').classList.toggle('fade-in');	
	// 	}		
    // }

	
	// sliders
	var introslider = new Swiper('.intro-slider', {
		slidesPerView: 1,
		spaceBetween: 0,
		loop:true,	
		pagination: {
		  el: '.i-pagination',
		  clickable: true,
		},
		navigation: {
			nextEl: '.i-next',
			prevEl: '.i-prev',
		},
	});

	// document.querySelector('.menu-wrap__inner .close').onclick = function(e) {
	// 	e.preventDefault();
	// 	let menuwrap=document.querySelector(".header__col.nav");
	// 	menuwrap.classList.remove("open-menu");   	
	// 	document.querySelector(".cmn-toggle-switch").classList.remove('active');	
    // }
	

	// scroll 

	//   document.addEventListener('scroll', function(e) {
	// 	let heeder_el=document.querySelector('header');
	// 	let lastKnownScrollPosition = window.scrollY;
		
	// 	if(lastKnownScrollPosition>0) {
	// 		heeder_el.classList.add("moved");
	// 		// document.querySelector('.light').classList.add("d-none");
	// 		// document.querySelector('.dark').classList.remove("d-none");
	// 	}
	// 	else{
	// 		heeder_el.classList.remove("moved");
	// 		// document.querySelector('.dark').classList.add("d-none");
	// 		// document.querySelector('.light').classList.remove("d-none");
	// 	}		
	//   });


	// ask events

	var askitems = document.querySelectorAll('.ask-item__header');    
	[].forEach.call( askitems, function(el) {
		
		el.onclick = function(e) {
			this.classList.toggle('header_opened');
			this.nextElementSibling.classList.toggle('body_opened');			
		}
	});

	if(document.body.contains(document.querySelector('.open-modal'))) {
		document.querySelector('.open-modal').onclick = function(e) {		
			e.preventDefault();

			document.querySelector('.modal-overlay_1').classList.toggle('modal-opened');
			document.querySelector('.modal-overlay_1').classList.toggle('fade-in');		
		};

	}

	document.querySelector('.modal__close').onclick = function(e) {		
		e.preventDefault();
		document.querySelector('.modal-overlay_1').classList.remove('modal-opened');		
    };
	


	const anchors = document.querySelectorAll('a.go-down')

	for (let anchor of anchors) {
	anchor.addEventListener('click', function (e) {
		e.preventDefault()		
		const blockID = anchor.getAttribute('href')		
		document.querySelector(blockID).scrollIntoView({
		behavior: 'smooth',
		block: 'start'
		})
	})
	}





	let inputs = document.querySelectorAll('.modal input');
	let ver = true;	
	inputs.forEach(function (item, idx) {
        item.addEventListener('input', function () {
			
			let valarray=[];
			inputs.forEach(function (item, idx) {
				console.log(item);
				if(item.value !== ''){ 
					ver = true;
					valarray[idx]=true;
				} 
				else{
					ver=false;
					valarray[idx]=false;
				}
			});	

			// valarray.forEach(function(item, i, arr) {
			// 	console.log( i + ": " + item + " (массив:" + arr + ")" );
			// });
			
			let checker = arr => arr.every(v => v === true);


			if(checker(valarray)){
				document.querySelector('.modal .btn_fill').disabled = false; 
			} else {
				document.querySelector('.modal .btn_fill').disabled = true; 
			}

        });
	});









		

	
})